import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import * as Sentry from "@sentry/vue";
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import Toaster from '@meforma/vue-toaster';
import VueGoogleMaps from '@fawmi/vue-google-maps';
// import Socket from './composables/webSocket/chat_websocket_send';
import CanvasJSChart from '@canvasjs/vue-charts';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueApexCharts from "vue3-apexcharts";
import VueSweetalert2 from 'vue-sweetalert2';

// import { VueReCaptcha} from 'vue-recaptcha-v3'
const i18n = createI18n({
    locale:localStorage.getItem('lang') || 'en',
    messages: {
      en: {
        Search:'Search',
        PDF:'PDF',
        logout:'Sign out',
        Signup:'Sign up',
        password:'Password',
        Login:'Login',
        FullName:'Full Name',
        PhoneNumber:'Phone Number',
        Email:'Email',
        Dashboard:'Dashboard',
        Username :'User name',
        AccountType:'Account Type',
        Freelancer:'Freelancer',
        AuditFirm:'Audit Firm',
        Suppliers :'Suppliers ',
        Regulator:'Regulator',
        Organization:'Organization',
        projects:'projects',
        Certificate:'Certificate',
        Compliance:'Compliance',
        InProcess:'In Process',
        Pending:'Pending',
        All:'All',
        Date:'Date',
        Action:'Action'
      },
      ar: {
        Search:'بحث',
        PDF:'PDF احصل على ملف',
        logout:'تسجيل خروج',
        Signup:'إشتراك',
        password:'اسم المستخدم',
        Login:'دخول',
        FullName:'الإسم بالكامل',
        PhoneNumber:'رقم الهاتف ',
        Email:'إيميل',
        Dashboard:'الصفحة الرئيسية',
        Username :'اسم المستخدم',
        AccountType:'حساب',
        Freelancer:'الأشخاص المستقلين',
        AuditFirm:'شركات التدقيق',
        Suppliers :'الموردين ',
        Regulator:'جهة حكومية',
        Organization:'شركة',
        projects:'المشاريع',
        Certificate:'الشهادات',
        Compliance:'الضوابط ',
        InProcess:'تحت التنفيذ',
        Pending:'معلقة',
        All:'الكل',
        Date:'التاريخ',
        Action:"الاجراءات"

      }
    }
});

const app = createApp(App);

if (window.location.hostname !== "localhost") {
  // Init Sentry
  Sentry.init({
    app,
    dsn: "https://818786362f03bc5415e702c1c5be58de@o4507843216015360.ingest.de.sentry.io/4507854568423504",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost", 
      /^https:\/\/apis\.glasshub\.ai/, 
      /^https:\/\/apis\.dev\.glasshub\.ai/,
      /^https:\/\/apis\.staging\.glasshub\.ai/, 
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.use(VueGoogleMaps, {
    load: {
        // 'YOUR_API_KEY_COMES_HERE'
        // 'AIzaSyDNS7nXnbp8nqbAzU5h1nuqAK5kaWAsP90'
        key: 'AIzaSyAdxmehf5dM8SXOKluCcljMb6QfzoNjv1g',
        libraries: "places"
    },
});

// app.use(Socket())
app.use(router);
app.use(VueApexCharts);
app.use(createPinia());
app.use(Toaster,{timeout: 3000});
app.use(i18n);
app.use(CanvasJSChart); 
app.component('VueDatePicker', VueDatePicker);
app.component("v-select", vSelect);
app.use(VueSweetalert2);

// app.use(VueReCaptcha, { siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' });
app.mount('#app');
